import React from 'react';
import Layout from '../layouts';
import { Container, Row, Col } from 'react-bootstrap';

function ComingSoon () {
    
  return ( 
      <Layout>
    {
      /*====== COMIMG SOON PART START ======*/
    }
    <div className="error-area pt-120 pb-115">
      <Container>
        <Row className="justify-content-center pt-5">
          <Col lg="12">
            <div className="error-item text-center">
              <div id="simple_timer" />
              <h2 className="title">
                This Website is Coming <br /> Very Soon
              </h2>
              <span>Wait for it please</span>
            </div>
            {/* error item */}
          </Col>
        </Row>
      </Container>
    </div>
    </Layout>
  );
}
 
export default ComingSoon;