import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function Banner() {

    return ( 
      <div className="banner-area-2 bg_cover" style={{backgroundImage: `url(${process.env.PUBLIC_URL}'/assets/images/banner-bg.jpg')`}}>
        <div className="banner-overlay">
          <Container>
            <Row className="row justify-content-center">
              <Col lg="col-lg-9">
                <div className="banner-content text-center">
                  <span>Get Quality Item &amp; Goods</span>
                  <h1 className="title">IT Solutions</h1>
                  <p>Make your products with good &amp; professionals</p>
                  <ul>
                    <li><Link className="main-btn wow fadeInUp" to="/contact" data-wow-duration=".3s" data-wow-delay=".5s" style={{visibility: 'visible', animationDuration: '0.3s', animationDelay: '0.5s', animationName: 'fadeInUp'}}>Get A Quote</Link></li>
                    <li><Link className="main-btn main-btn-2 wow fadeInUp" to="/services" data-wow-duration=".7s" data-wow-delay=".7s" style={{visibility: 'visible', animationDuration: '0.7s', animationDelay: '0.7s', animationName: 'fadeInUp'}}>Learn More</Link></li>
                  </ul>
                </div> 
              </Col>
            </Row> 
          </Container> 
        </div>
      </div>
  );
  
}
 
export default Banner;