import React from 'react';
import ServicesPlansPart from '../../componets/servicesPart/ServicesPlansPart';
import LatestNewsPart from '../../componets/latestNewsPart/Index';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import ModalVideo from 'react-modal-video'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
} from '@fortawesome/free-solid-svg-icons';
import {faPlay} from "@fortawesome/free-solid-svg-icons";


function ServicesDetails() {
  const [isOpen, setOpen] = React.useState(false)

  return ( 
    <Layout>
      <div className="page-title-area">
        <Container>
          <Row>
            <Col lg="12">
              <div className="page-title-item text-center">
                <h2 className="title">Services Details</h2>
                <nav aria-label="breadcrumb">

                
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link  to={"/"}>Home </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                    Services Details
                    </li>
                  </ol>
                </nav>
              </div>
              {/* page title */}
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
      {
        /*====== PAGE TITLE PART ENDS ======*/
      }
      <div className="services-optimization-area pt-80 pb-120">
            <Container>
              <Row className="align-items-center">
                <Col lg="4" md="6">
                  <div className="services-optimization-thumb mt-40">
                    <img src="assets/images/services-op-1.jpg" alt="" />
                  </div>
                  <div className="services-optimization-thumb-2">
                    <img src="assets/images/services-op-2.jpg" alt="" />
                  </div>
                </Col>
                <Col lg="6" className="order-lg-2 order-1">
                  <div className="services-optimization-content mt-40">
                    <span>Search Enging Optimization</span>
                    <h3 className="title">Nothing to do anything.</h3>
                    <p>
                      Does any industry face a more complex audience journey and marketing
                      sales process than B2B technology? Consider the number of people who
                      influence a sale, the length of the decision-making cycle, the
                      competing interests of the people who purchase, implement, manage,
                      and use the technology. It’s a lot meaningful content here.
                    </p>
                    <div className="action-support-item">
                      <p>Make A Call</p>
                      <span>897 876 76 56 4</span>
                      <i className="fal fa-phone" />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
         
          <div className="intro-video-area bg_cover"
          style={{ backgroundImage: "url(assets/images/intro-video-bg.jpg)" }}
        >
          <div className="intro-overlay">
            <Container>
              <Row>
                <Col lg="6">
                  <div className="intro-video-content mt-30">
                    <span>Intro Video</span>
                    <h2 className="title">How we growth our business.</h2>
                    <p className="text-1">
                      The introduction of cloud and mobile technologies into enterprise
                      software.
                    </p>
                    <p className="text-2">
                      hether you are building an enterprise web portal or a
                      state-of-the-art website, you always need the right modern tools.
                      Well-built and maintained PHP frameworks provide those tools in
                      abundance, allowing maintained PHP frameworks provide those tools
                      in abundance, allowing developers to save time, re-use code, and
                      streamline the back end. As software development tools
                      continuously.
                    </p>
                    <Link 
                      className="main-btn wow fadeInLeft"
                      data-wow-duration="1s"
                      data-wow-delay=".1s"
                      to={"/services"}
                    >
                      Learn More
                    </Link>
                  </div>
                  {/* intro video content */}
                </Col>
                <Col lg="6">
                  <div className="intro-thumb mt-30">
                  <ModalVideo channel='youtube' isOpen={isOpen} videoId='L61p2uyiMSo'  onClose={() => setOpen(false)} />
                    <img src="assets/images/intro-thumb.jpg" alt="" />
                    <Link  className="video-popup" to="#video"  onClick={()=> setOpen(true)}>
                      <FontAwesomeIcon icon={faPlay} size={`3x`} className={`pl-2 pt-3`}/>
                    </Link>
                  </div>
                  {/* intro thumb */}
                </Col>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
        </div>
        
      <ServicesPlansPart/>
      <LatestNewsPart/>
      </Layout>
  );

}
 
export default ServicesDetails;