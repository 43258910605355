import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../layouts';

function AboutPage() {
  const [isOpen, setOpen] = useState(false);

  return ( 
    <Layout>
      <div className="page-title-area">
      <Container>
        <Row>
          <Col lg="12">
            <div className="page-title-item text-center">
              <h2 className="title">About Us</h2>
              <nav aria-label="breadcrumb">

              
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={'index'}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About
                  </li>
                </ol>
              </nav>
            </div>
            {/* page title */}
          </Col>
        </Row>
        {/* row */}
      </Container>
      {/* container */}
    </div>
      {
        /*====== PAGE TITLE PART ENDS ======*/
      }
      <div className="about-experience-area pb-120">
        <Container>
        <Row>
          <Col lg="5">
            <div className="experience-item">
              <span>25 Years Of Experience</span>
              <h2 className="title">Not only explore for job done.</h2>
            </div>
            {/* experience item */}
          </Col>
          <Col lg="6" className="offset-lg-1">
            <div className="experience-item">
              <p>
                Does any industry face a more complex audience journey and marketing
                sales process than B2B technology? Consider the number of people who
                influence a sale, the length of the decision-making cycle, the
                competing interests of the people who purchase, implement, manage,
                and use the technology. It’s a lot meaningful content here.
              </p>
              <Link to={"/about"}>Read More</Link>
            </div>
            {/* experience item */}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <div
            className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
            data-wow-duration=".5s"
            data-wow-delay=".2s"
          >
            <div className="single-experience mt-30">
              <img src="assets/images/experience-1.jpg" alt="" />
              <div className="experience-overlay">
                <h5 className="title">Design &amp; Development</h5>
                <p>
                  The functional goal of technical content is to help people use a
                  product.
                </p>
                <Link to={"about"}>Read More</Link>
                <i className="fal fa-laptop-code" />
              </div>
            </div>
            {/* single experience */}
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay=".4s"
          >
            <div className="single-experience mt-30">
              <img src="assets/images/experience-2.jpg" alt="" />
              <div className="experience-overlay">
                <h5 className="title">Technical Support</h5>
                <p>
                  The functional goal of technical content is to help people use a
                  product.
                </p>
                <Link to="/home-1">Read More</Link>
                <i className="fal fa-headphones-alt" />
              </div>
            </div>
            {/* single experience */}
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay=".6s"
          >
            <div className="single-experience mt-30">
              <img src="assets/images/experience-3.jpg" alt="" />
              <div className="experience-overlay">
                <h5 className="title">Digital Marketing</h5>
                <p>
                  The functional goal of technical content is to help people use a
                  product.
                </p>
                <Link to={"/about"}>Read More</Link>
                <i className="fal fa-analytics" />
              </div>
            </div>
            {/* single experience */}
          </div>
        </Row>
        {/* row */}
      </Container>
        {/* container */}
      </div>
    
      <div className="about-intro-area pt-120">
        <Container>
          <ModalVideo channel='youtube' isOpen={isOpen} videoId='TdSA7gkVYU0' onClose={() => setOpen(false)} />
          <Row>
            <Col lg="6">
              <div className="intro-thumb">
                <img src="/assets/images/about-intro-thumb.jpg" alt="" />
                <Link className="video-popup" to="#viewo" onClick={()=> setOpen(true)}>
                  <i className="fas fa-play" />
                </Link>
              </div>
            </Col>
            <Col lg="6">
              <div className="intri-content">
                <span>Intro Video</span>
                <h2 className="title">
                  How we growth <br /> our business.
                </h2>
                <p className="text-1">
                  The introduction of cloud and mobile technologies into enterprise
                  software.
                </p>
                <p className="text-2">
                  hether you are building an enterprise web portal or a
                  state-of-the-art website, you always need the right modern tools.
                  Well-built and maintained PHP frameworks provide those tools in
                  abundance, allowing maintained PHP frameworks provide those tools in
                  abundance, allowing developers to save time, re-use code, and
                  streamline the back end. As software development tools continuously.
                </p>
                <Link
                  className="main-btn wow fadeInUp"
                  to={"/"}
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      
      <div
        className="about-faq-area pb-100 bg_cover"
        style={{ backgroundImage: "url(assets/images/faq-bg.jpg)" }}
      >
        <Container>
          <Row>
            <Col lg="6">
              <div className="about-faq-content">
                <span>FAQ</span>
                <h2 className="title">Get Every answer from here.</h2>
              </div>

              <div className="faq-accordion-3 mt-30">
                <Accordion defaultActiveKey="2">
                {[0, 1,2,3,4,5].map((key) => (
                <Card
                  key={key}
                  className="card wow fadeInLeft"
                  data-wow-duration=".1.2s"
                  data-wow-delay=".2s"
                >
                  <Card.Header>
                    <Accordion.Toggle 
                      as={Link}
                      eventKey={key}
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >

                      <FontAwesomeIcon icon={faMagic} className="mr-2" />
                      Technical content may have persuasive objectives
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={key}>
                    <Card.Body>
                      <p>
                        Today, the term Information Technology (IT) has ballooned to
                        encompass is real. Today, the term Information Technology
                        (IT) has ballooned to encompass is real.Today, the term
                        Information Technology (IT) has ballooned to encompass is
                        real.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
                  
                </Accordion>
              </div>
              {/* faq accordion */}
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
      
      </Layout>
  );
}
 
export default AboutPage;