import React from 'react';
import Layout from '../../layouts';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function CaseStudy() {

  return ( 
  <Layout>
    <div className="page-title-area">
      <Container>
        <Row>
          <Col lg="12">
            <div className="page-title-item text-center">
              <h2 className="title">Our Portfolio</h2>
              <nav aria-label="breadcrumb">

              
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link  to={"/"}>Home </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Our Portfolio
                  </li>
                </ol>
              </nav>
            </div>
            {/* page title */}
          </Col>
        </Row>
        {/* row */}
      </Container>
      {/* container */}
    </div>
    {
      /*====== PAGE TITLE PART ENDS ======*/
    }
     <div className="news-area news-area-2">
      <Container>
        <Row className="justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/news-1.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/news-2.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-1.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-2.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-3.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-4.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-5.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-6.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-7.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-8.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-9.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-10.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-11.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-12.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-9">
            <div className="single-news mt-30">
              <img src="assets/images/case-study-2.jpg" alt="" />
              <div className="single-news-overlay">
                <span>Design/SEO</span>
                <h5 className="title">
                  <Link  to={"/"}>A series of iOS 7 inspired vector icons.</Link>
                </h5>
                <Link  to={"/"}>
                  <i className="fal fa-long-arrow-right" />
                </Link>
              </div>
            </div>
            {/* single news */}
          </div>
          <Col lg="8">
            <div className="case-study-btn text-center mt-40">
              <Link  className="main-btn" to={"/"}>
                Load More +
              </Link>
            </div>
          </Col>
        </Row>
        {/* row */}
      </Container>
      {/* container */}
    </div>
    
    </Layout>
  );
}
 
export default CaseStudy;