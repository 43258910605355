import React from 'react';
import './index.scss'
import Layout from '../../layouts';
import {Link} from "react-router-dom";
import {Container, Row, Col, Button} from 'react-bootstrap';
import data from './data';
import Slider from "react-slick";

function SampleNextArrow(props) {
  const {className, style, onClick} = props;

  const nextArrowStyle = {
    display: "block",
    zIndex: '3',
    height: "30px",
    width: "30px",
    textAlign: "center",
    lineHeight: "30px",
    borderRadius: "50%",
    fontSize: "20px",
    cursor: "pointer",
    right: "-8px",
    "&::before": {
      marginRight: "14px",
      backgroundColor: "#000",
      color: "#222020"
    },
    "&::hover": {
      backgroundColor: "#fff"
    }
  };

  return (<div
    className={className}
    style={{
    ...style,
    ...nextArrowStyle
  }}
    onClick={onClick}/>);
}

function SamplePrevArrow(props) {

  const {className, style, onClick} = props;
  const prevArrowStyle = {
    display: "block",
    zIndex: '3',
    height: "30px",
    width: "30px",
    textAlign: "center",
    lineHeight: "30px",
    borderRadius: "50%",
    fontSize: "20px",
    cursor: "pointer",
    left: "-8px",
    "&::before": {
      marginLeft: "14px",
      backgroundColor: "#000",
      color: "#222020"
    },
    "&::hover": {
      backgroundColor: "#fff"
    }
  };
  return (<div
    className={className}
    style={{
    ...style,
    ...prevArrowStyle
  }}
    onClick={onClick}/>);
}

function ProductDetailPage() {

  const [showPriceType,
    setShowPriceType] = React.useState(false);
  const [licenseType,
    setLicenseType] = React.useState(data.prices.regular);

  const [sliderHover,
    setSliderHover] = React.useState(false);
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>
  };
  return (
    <Layout>
      <div className={`product-header`}>
        <Container>
          <Row>
            <Col sm="12">

              <div className="page-title-item">
                <h2 className="title">
                  {data.name}
                </h2>
              </div>

              <div className={`product-header-tab`}>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button className="nav-link active">Overview
                    </button>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"products-details"}>Comments
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"products-details"}>Reviews
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"products-details"}>Support
                    </Link>
                  </li>

                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="shop-area gray-bg  pb-120">
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="12">
              <div className={`product-preview`}>
                <div className="product-slider">
                  <Row>
                    <Col xl="10" lg="10" md="12" sm="12" className="mt-3">
                      <div
                        style={{
                        display: 'block'
                      }}
                        onMouseEnter={() => setSliderHover(true)}
                        onMouseLeave={() => setSliderHover(false)}>
                        <Slider {...settings}>
                          <div>
                            <img src={data.previews.preview.landscape_url} alt={``}/>
                          </div>
                        </Slider>
                        <div
                          className={`product-button`}
                          style={sliderHover
                          ? {
                            display: "block"
                          }
                          : {
                            display: 'none'
                          }}>

                          <div className={'button-group'}>
                            <button
                              type="button"
                              className="btn btn-outline-primary"
                              style={{
                              marginRight: "5px"
                            }}>Screenshots</button>
                            <span></span>
                            <button type="button" className="btn btn-outline-secondary">Live Demo</button>
                          </div>

                        </div>
                      </div>
                    </Col>
                    <Col
                      xl="2"
                      lg="2"
                      className="d-md-none d-lg-block d-xl-block  mt-3 pl-0 mt-0 ml-0 ">
                      <div className="info">
                        <div>
                          <p>PRICE</p>
                          <strong>{data.prices.regular.value}</strong>
                        </div>
                        <div>
                          <p>SALES</p>
                          <strong>{data.number_of_sales}</strong>
                        </div>
                        <div>
                          <p>LOVED</p>
                          <strong>{data.number_of_loves}</strong>
                        </div>
                        <div>
                          <p>TYPE</p>
                          <Link to="#wordpress">{data.category}</Link>
                        </div>
                      </div>
                    </Col>

                  </Row>

                  <Row>
                    <Col md="12">
                      <div
                        id="product-content-inner"
                        className="product-content-inner"
                        dangerouslySetInnerHTML={{
                        __html: data.description
                      }}/>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg="4" md="12" sm="12">
              <div className="sidebar-widgets">

                <div className="sidebar-widget">
                  <div className="title d-flex justify-content-between">
                    <div className="price-box-select-price">

                      <Button onClick={() => setShowPriceType(!showPriceType)}>
                        <strong className="title">{licenseType.name}
                            <i className={showPriceType?'fas fa-sort-up pt-2 ml-2':'fas fa-sort-down ml-2'}></i>
                        </strong>
                      </Button>

                      <div
                        className="price-tooltip "
                        style={showPriceType
                        ? {
                          display: "block"
                        }
                        : {
                          display: 'none'
                        }}>
                        <div className="price-tooltip__arrow"></div>
                        <div
                          className={`price-tooltip__box`}
                          onClick={() => {
                          setLicenseType(data.prices.regular);
                          setShowPriceType(false)
                        }}>
                          <div className="d-flex ">
                            <div className="font-weight-bold">Regular License</div>
                            <div className="flex-shrink-1">
                              <strong>{data.prices.regular.value}</strong>
                            </div>
                          </div>
                          <p className="font-weight-normal">Use, by you or one client, in a single end product which end users
                            <strong>are not</strong>
                            charged for. The total price includes the item price and a buyer fee.</p>

                        </div>
                        <div
                          className={`price-tooltip__box`}
                          onClick={() => {
                          setLicenseType(data.prices.extended);
                          setShowPriceType(false)
                        }}>

                          <div className="d-flex price-tooltip__box">
                            <div className="p-2 w-100 bd-highlight">Extended License</div>
                            <div className="p-2 flex-shrink-1 bd-highlight">
                              <strong>{data.prices.extended.value}</strong>
                            </div>
                          </div>
                          <p className="font-weight-normal">Use, by you or one client, in a single end product which end users
                            <strong>can be</strong>
                            charged for. The total price includes the item price and a buyer fee.</p>
                          <p>
                            <a href="https://themeforest.net/licenses/standard">View license details</a>
                          </p>

                        </div>

                      </div>
                    </div>
                    <div>
                      <strong className="product-price">{licenseType.value}</strong>
                    </div>
                  </div>

                  <div className="sidebar-list mt-4">
                    <ul>
                      <li>
                        <i className="fas fa-check-circle mr-2 text-primary"></i>
                        Free technical support</li>
                      <li>
                        <i className="fas fa-check-circle mr-2 text-primary"></i>
                        Future product updates</li>
                      <li>
                        <i className="fas fa-check-circle mr-2 text-primary"></i>
                        Quality checked by Envato</li>
                      <li>
                        <i className="fas fa-check-circle mr-2 text-primary"></i>
                        Lowest price guarantee</li>
                    </ul>
                  </div>

                  <button type="button" className="btn btn-primary btn-lg btn-block mt-4">Purchase now</button>

                </div>

                <div className="sidebar-widget">
                    
                  <div className="sidebar-widget-title">
                    <h5 className="title">Information</h5>
                  </div>

                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Last Update</td>
                        <td>
                          13 February 20
                        </td>
                      </tr>
                      <tr>
                        <td>Created</td>
                        <td>
                          13 February 20
                        </td>
                      </tr>
                      <tr>
                        <td>High Resolution</td>
                        <td>Yes</td>
                      </tr>
                      <tr>
                        <td>Compatible Browsers</td>
                        <td>IE10, IE11, Firefox, Safari, Opera, Chrome, Edge</td>
                      </tr>
                      <tr>
                        <td>Compatible With</td>
                        <td>
                          ReactJS, Bootstrap 4.x</td>
                      </tr>
                      <tr>
                        <td>ThemeForest Files Included</td>
                        <td>HTML Files, CSS Files, Sass Files, JS Files</td>
                      </tr>
                      <tr>
                        <td>Columns</td>
                        <td>
                          4+</td>
                      </tr>
                      <tr>
                        <td>Documentation</td>
                        <td>
                          Well Documented</td>
                      </tr>
                      <tr>
                        <td>Layout</td>
                        <td>
                          Responsive</td>
                      </tr>
                      <tr>
                        <td>Tags</td>
                        <td>
                          agency, business, corporate, creative, IT services, modern, portfolio, react,
                          reactjs-template, services, solutions, startup, technology
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );

}

export default ProductDetailPage;