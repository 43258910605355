import React from 'react';
import {Helmet} from "react-helmet";
import Header from './partials/header/Header';
import Footer from './partials/footer/index';

function Layout(props) {

    return (
        <React.Fragment>
            <Helmet>
                <title> Omnivus - IT Solutions & Services React JS Template </title>
            </Helmet>
            <Header />
                { props.children }
            <Footer/>
        </React.Fragment>
    )


}

export default Layout