import React from 'react';
import Layout from '../../layouts';
import ProgressBarPart from '../../componets/progressBarPart/Index';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';

function TeamDetailsPage() {

  return ( 
    <Layout>

    <div className="page-title-area">
      <Container>
        <Row>
          <Col lg="12">
            <div className="page-title-item text-center">
              <h2 className="title">Team Details</h2>
              <nav aria-label="breadcrumb">

              
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link  to="/">Home </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Team Details
                  </li>
                </ol>
              </nav>
            </div>
            {/* page title */}
          </Col>
        </Row>
        {/* row */}
      </Container>
      {/* container */}
    </div>
      {/* /*====== PAGE TITLE PART ENDS ======*/

      /*====== TEAM DETAILS PART START ======*/ }

    <div className="team-details-area pt-120">
      <Container>
        <Row className="align-items-center">
          <Col lg="6">
            <div className="team-details-thumb">
              <img src="assets/images/team-details-thumb.jpg" alt="" />
            </div>
          </Col>
          <Col lg="6">
            <div className="team-details-content">
              <h4 className="title">Rosalina D. Williamson</h4>
              <span>Founder</span>
              <p className="pb-15">
                Does any industry face a more complex audience journey and marketing
                sales process than B2B technology? Consider the number of people who
                influence a sale, the length of the decision-making cycle, the
                competing interests of the people who purchase, implement, manage,
                and use the technology. It’s a lot meaningful content here.
              </p>
              <p>
                As a result, most of us need to know how to use computers. Our
                knowledge of computers will help us to tap into challenging career
                opportunities and enhance the quality of our lives. It is imperative
                that quality students be encouraged and motivated to study computers
                and become capable and responsible IT professionals. The education
                model needs to align itself with dynamically changing technology to
                meet the growing need for skilled IT manpower and deliver
                state-of-the-art, industry relevant and technology ready programs.​
              </p>
              <ul>
                <li>
                  <Link  to={"/"}>
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link  to={"/"}>
                    <i className="fab fa-twitter" />
                  </Link>
                </li>
                <li>
                  <Link  to={"/"}>
                    <i className="fab fa-behance" />
                  </Link>
                </li>
                <li>
                  <Link  to={"/"}>
                    <i className="fab fa-linkedin-in" />
                  </Link>
                </li>
                <li>
                  <Link  to={"/"}>
                    <i className="fab fa-youtube" />
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

      {/* /*====== TEAM DETAILS PART ENDS ======*/}


    <ProgressBarPart/>
              
    <div
        className="about-faq-area pb-100 bg_cover"
        style={{ backgroundImage: "url(assets/images/faq-bg.jpg)" }}
      >
        <Container>
          <Row>
            <Col lg="6">
              <div className="about-faq-content">
                <span>FAQ</span>
                <h2 className="title">Get Every answer from here.</h2>
              </div>

              <div className="faq-accordion-3 mt-30">
                <Accordion defaultActiveKey="2">
                {[0, 1,2,3,4,5].map((key) => (
                <Card
                  key={key}
                  className="card wow fadeInLeft"
                  data-wow-duration=".1.2s"
                  data-wow-delay=".2s"
                >
                  <Card.Header>
                    <Accordion.Toggle 
                      as={Link}
                      eventKey={key}
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >

                      <FontAwesomeIcon icon={faMagic} className="mr-2" />
                      Technical content may have persuasive objectives
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={key}>
                    <Card.Body>
                      <p>
                        Today, the term Information Technology (IT) has ballooned to
                        encompass is real. Today, the term Information Technology
                        (IT) has ballooned to encompass is real.Today, the term
                        Information Technology (IT) has ballooned to encompass is
                        real.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
                  
                </Accordion>
              </div>
              {/* faq accordion */}
            </Col>
          </Row>
          {/* row */}
        </Container>
        {/* container */}
      </div>
      

    </Layout>
  );
    
}
 
export default TeamDetailsPage;