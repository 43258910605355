export const data = {
  "id": 28444535,
  "name": "Arlo - Personal Portfolio Jekyll Theme",
  "prices": {
    "regular": {
      "value": "$56",
      "name": "Regular License",
      "extend_support": {
        "price": "$20",
        "duration": "6 months"
      }
    },
    "multisite": {
      "value": "$120",
      "name": "Multisite License",
      "extend_support": {
        "price": "$20",
        "duration": "6 months"
      }
    },
    "extended": {
      "value": "$700",
      "name": "Extended License",
      "extend_support": {
        "price": "$60",
        "duration": "6 months"
      }
    }
  },
  "number_of_sales": 6,
  "number_of_loves": 120,
  "category": "WordPress",
  "updated_at": "2020-09-13T14:34:35+10:00",
  "attributes": [
    {
      "name": "columns",
      "value": null,
      "label": "Columns"
    }, {
      "name": "compatible-browsers",
      "value": [
        "IE11",
        "Firefox",
        "Safari",
        "Opera",
        "Chrome",
        "Edge"
      ],
      "label": "Compatible Browsers"
    }, {
      "name": "compatible-software",
      "value": [
        "Jekyll 3.6.x", "Jekyll 3.5.x", "Jekyll 3.4.x"
      ],
      "label": "Software Version"
    }, {
      "name": "compatible-with",
      "value": null,
      "label": "Compatible With"
    }, {
      "name": "demo-url",
      "value": "https://arlo-jekyll-theme.vercel.app/intro.html",
      "label": "Demo URL"
    }, {
      "name": "documentation",
      "value": "Well Documented",
      "label": "Documentation"
    }, {
      "name": "high-resolution",
      "value": "Yes",
      "label": "High Resolution"
    }, {
      "name": "layout",
      "value": "Responsive",
      "label": "Layout"
    }, {
      "name": "themeforest-files-included",
      "value": [
        "HTML Files", "CSS Files", "JS Files"
      ],
      "label": "ThemeForest Files Included"
    }
  ],
  "description": "<h2>New CV Resume Template [HTML]</h2>\n<img src=\"https://assets.ducor.vercel.a" +
      "pp/images/top-main-future2.jpg\" alt=\"Arlo - Personal Portfolio Jekyll Theme - " +
      "1\" />\n\n<p>Arlo &#8211; Personal Portfolio JEKEYLL Template is for many purpos" +
      "e. It&#8217;s creative, minimal and clean design. It has all of the features of " +
      "the business website. It&#8217;s suitable for any startup business, companies, a" +
      "gencies, and freelancers which need a professional way to showcase their project" +
      "s and services with 100% super responsive experience.</p>\n<h3 id=\"item-descrip" +
      "tion__full-features\">Full Features</h3>\n<ul>\n    <li>- Fully Responsive HTML5" +
      "</li>\n    <li>- CSS3 Animations</li>\n    <li>- SEO optimized</li>\n    <li>- M" +
      "obile Navigations</li>\n    <li>- Fully Responsive to all devices</li>\n    <li>" +
      "- Developer Friendly Coding</li>\n    <li>- Sticky Navigation</li>\n    <li>- Ve" +
      "ry Well Documented</li>\n    <li>- Free Updates</li>                            " +
      "     \n</ul>\n<h3 id=\"item-description__editing-template\">Editing Template</h3" +
      ">\n<p>In order to edit the file, you need to have Code Writer program installed " +
      "on your system. Just open files in code writer and edit them.</p>\n<h3 id=\"item" +
      "-description__credits\">Credits:</h3>\n<ul>\n    <li>Images &#8211; <a href=\"ht" +
      "tp://www.pexels.com\" rel=\"nofollow\">pexels.com</a> and <a href=\"http://www.5" +
      "00px.com\" rel=\"nofollow\">500px.com</a>\n    Images are not included.*   Fonts" +
      ": &#8220;Montserrat&#8221; and &#8220;Open Sans&#8221; free google fonts</li>\n " +
      "   <li>Free Font Based Icons by <a href=\"http://www.flaticon.com/\" rel=\"nofol" +
      "low\">Flaticon.com</a></li>\n</ul>\n<h3 id=\"item-description__change-log\">Chan" +
      "ge Log</h3>\n\n<p><strong>Version 1.0</strong> &#8211; Aug 06, 2020</p>\n<p>-  A" +
      "rlo- Personal Porfolio Template Launched</p>",
  "price_cents": 2400,
  "summary": "High Resolution: Yes, Compatible Browsers: IE11, Firefox, Safari, Opera, Chrome," +
      " Edge, Software Version: Jekyll 3.6.x, Jekyll 3.5.x, Jekyll 3.4.x",
  "rating": 0,
  "rating_count": 0,
  "published_at": "2020-09-07T21:47:42+10:00",
  "trending": false,
  "tags": [
    "colorful",
    "corporate",
    "creative",
    "css animations",
    "elegant",
    "image background",
    "Jekyll",
    "light skin",
    "multiple layouts",
    "one page",
    "parallax",
    "portfolio",
    "portfolio template",
    "single",
    "white skin"
  ],
  "previews": {
    "live_site": {
      "href": "/item/arlo-personal-portfolio-jekyll-theme/full_screen_preview/28444535",
      "type": "live_site"
    },
    "preview": {
      "icon_url": "https://s3.envato.com/files/304093321/thumbnail.png",
      "landscape_url": "https://s3.envato.com/files/304093322/patrick_preview.__large_preview.png"
    }
  }
}

export default data;